import { createTimes, setMidnight, createDate, createSlotTimeLimits, sortEventChunks, isFunction, setContent, keyEnter, bgEvent, helperEvent, toEventWithLocalDates, toViewWithLocalDates, task, max, resourceBackgroundColor, resourceTextColor, ghostEvent, createEventClasses, createEventContent, datesEqual, outsideRange, addDuration, cloneDate, eventIntersects, createEventChunk, setPayload, rect, floor, ancestor, repositionEvent, height, runReposition, limitToRange, addDay, prepareEventChunks, debounce, toISOString, btnTextDay, themeView, btnTextWeek } from '@event-calendar/core';
import { derived } from 'svelte/store';
import { SvelteComponent, init, safe_not_equal, ensure_array_like, create_slot, element, space, attr, insert, append, action_destroyer, is_function, update_slot_base, get_all_dirty_from_scope, get_slot_changes, transition_in, transition_out, detach, destroy_each, component_subscribe, create_component, mount_component, destroy_component, set_store_value, empty, binding_callbacks, construct_svelte_component, listen, group_outros, check_outros, run_all, set_style, noop, update_keyed_each, outro_and_destroy_block } from 'svelte/internal';
import { getContext, onMount, afterUpdate } from 'svelte';

function times(state) {
    return derived(
        [state.slotDuration, state._slotTimeLimits, state._intlSlotLabel],
        args => createTimes(setMidnight(createDate()), ...args)
    );
}

function slotTimeLimits(state) {
    return derived(
        [state.slotMinTime, state.slotMaxTime, state.flexibleSlotTimeLimits, state._viewDates, state._events],
        args => createSlotTimeLimits(...args)
    );
}

function groupEventChunks(chunks) {
    if (!chunks.length) {
        return;
    }

    sortEventChunks(chunks);

    // Group
    let group = {
        columns: [],
        end: chunks[0].end
    };
    for (let chunk of chunks) {
        let c = 0;
        if (chunk.start < group.end) {
            for (; c < group.columns.length; ++c) {
                if (group.columns[c].at(-1).end <= chunk.start) {
                    break;
                }
            }
            if (chunk.end > group.end) {
                group.end = chunk.end;
            }
        } else {
            group = {
                columns: [],
                end: chunk.end
            };
        }

        if (group.columns.length < c + 1) {
            group.columns.push([]);
        }
        group.columns[c].push(chunk);

        chunk.group = group;
        chunk.column = c;
    }
}

function createAllDayContent(allDayContent) {
    let text = 'all-day';
    let content;
    if (allDayContent) {
        content = isFunction(allDayContent) ? allDayContent({text}) : allDayContent;
        if (typeof content === 'string') {
            content = {html: content};
        }
    } else {
        content = {
            html: text
        };
    }

    return content;
}

/* packages/time-grid/src/Section.svelte generated by Svelte v4.2.19 */
const get_lines_slot_changes = dirty => ({});
const get_lines_slot_context = ctx => ({});

function get_each_context$5(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[9] = list[i];
	return child_ctx;
}

// (14:4) {#each $_times as time}
function create_each_block$5(ctx) {
	let time_1;
	let time_1_class_value;
	let time_1_datetime_value;
	let setContent_action;
	let mounted;
	let dispose;

	return {
		c() {
			time_1 = element("time");
			attr(time_1, "class", time_1_class_value = /*$theme*/ ctx[1].time);
			attr(time_1, "datetime", time_1_datetime_value = /*time*/ ctx[9][0]);
		},
		m(target, anchor) {
			insert(target, time_1, anchor);

			if (!mounted) {
				dispose = action_destroyer(setContent_action = setContent.call(null, time_1, /*time*/ ctx[9][2] ? /*time*/ ctx[9][1] : ''));
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*$theme*/ 2 && time_1_class_value !== (time_1_class_value = /*$theme*/ ctx[1].time)) {
				attr(time_1, "class", time_1_class_value);
			}

			if (dirty & /*$_times*/ 4 && time_1_datetime_value !== (time_1_datetime_value = /*time*/ ctx[9][0])) {
				attr(time_1, "datetime", time_1_datetime_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty & /*$_times*/ 4) setContent_action.update.call(null, /*time*/ ctx[9][2] ? /*time*/ ctx[9][1] : '');
		},
		d(detaching) {
			if (detaching) {
				detach(time_1);
			}

			mounted = false;
			dispose();
		}
	};
}

function create_fragment$8(ctx) {
	let div1;
	let div0;
	let div0_class_value;
	let setContent_action;
	let t0;
	let div1_class_value;
	let t1;
	let div3;
	let div2;
	let div2_class_value;
	let t2;
	let div3_class_value;
	let current;
	let mounted;
	let dispose;
	let each_value = ensure_array_like(/*$_times*/ ctx[2]);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block$5(get_each_context$5(ctx, each_value, i));
	}

	const lines_slot_template = /*#slots*/ ctx[8].lines;
	const lines_slot = create_slot(lines_slot_template, ctx, /*$$scope*/ ctx[7], get_lines_slot_context);
	const default_slot_template = /*#slots*/ ctx[8].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[7], null);

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			t0 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t1 = space();
			div3 = element("div");
			div2 = element("div");
			if (lines_slot) lines_slot.c();
			t2 = space();
			if (default_slot) default_slot.c();
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[1].sidebarTitle);
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[1].sidebar);
			attr(div2, "class", div2_class_value = /*$theme*/ ctx[1].lines);
			attr(div3, "class", div3_class_value = /*$theme*/ ctx[1].days);
			attr(div3, "role", "row");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			append(div1, t0);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div1, null);
				}
			}

			insert(target, t1, anchor);
			insert(target, div3, anchor);
			append(div3, div2);

			if (lines_slot) {
				lines_slot.m(div2, null);
			}

			append(div3, t2);

			if (default_slot) {
				default_slot.m(div3, null);
			}

			current = true;

			if (!mounted) {
				dispose = action_destroyer(setContent_action = setContent.call(null, div0, /*allDayText*/ ctx[0]));
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (!current || dirty & /*$theme*/ 2 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[1].sidebarTitle)) {
				attr(div0, "class", div0_class_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty & /*allDayText*/ 1) setContent_action.update.call(null, /*allDayText*/ ctx[0]);

			if (dirty & /*$theme, $_times*/ 6) {
				each_value = ensure_array_like(/*$_times*/ ctx[2]);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context$5(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block$5(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div1, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (!current || dirty & /*$theme*/ 2 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[1].sidebar)) {
				attr(div1, "class", div1_class_value);
			}

			if (lines_slot) {
				if (lines_slot.p && (!current || dirty & /*$$scope*/ 128)) {
					update_slot_base(
						lines_slot,
						lines_slot_template,
						ctx,
						/*$$scope*/ ctx[7],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[7])
						: get_slot_changes(lines_slot_template, /*$$scope*/ ctx[7], dirty, get_lines_slot_changes),
						get_lines_slot_context
					);
				}
			}

			if (!current || dirty & /*$theme*/ 2 && div2_class_value !== (div2_class_value = /*$theme*/ ctx[1].lines)) {
				attr(div2, "class", div2_class_value);
			}

			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 128)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[7],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[7])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[7], dirty, null),
						null
					);
				}
			}

			if (!current || dirty & /*$theme*/ 2 && div3_class_value !== (div3_class_value = /*$theme*/ ctx[1].days)) {
				attr(div3, "class", div3_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(lines_slot, local);
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(lines_slot, local);
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div1);
				detach(t1);
				detach(div3);
			}

			destroy_each(each_blocks, detaching);
			if (lines_slot) lines_slot.d(detaching);
			if (default_slot) default_slot.d(detaching);
			mounted = false;
			dispose();
		}
	};
}

function instance$8($$self, $$props, $$invalidate) {
	let $allDayContent;
	let $theme;
	let $_times;
	let { $$slots: slots = {}, $$scope } = $$props;
	let { allDayContent, theme, _times } = getContext('state');
	component_subscribe($$self, allDayContent, value => $$invalidate(6, $allDayContent = value));
	component_subscribe($$self, theme, value => $$invalidate(1, $theme = value));
	component_subscribe($$self, _times, value => $$invalidate(2, $_times = value));
	let allDayText;

	$$self.$$set = $$props => {
		if ('$$scope' in $$props) $$invalidate(7, $$scope = $$props.$$scope);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$allDayContent*/ 64) {
			$$invalidate(0, allDayText = createAllDayContent($allDayContent));
		}
	};

	return [
		allDayText,
		$theme,
		$_times,
		allDayContent,
		theme,
		_times,
		$allDayContent,
		$$scope,
		slots
	];
}

class Section extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$8, create_fragment$8, safe_not_equal, {});
	}
}

/* packages/time-grid/src/Body.svelte generated by Svelte v4.2.19 */

function get_each_context$4(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[23] = list[i];
	return child_ctx;
}

// (34:8) <Section>
function create_default_slot$1(ctx) {
	let current;
	const default_slot_template = /*#slots*/ ctx[16].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[18], null);

	return {
		c() {
			if (default_slot) default_slot.c();
		},
		m(target, anchor) {
			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 262144)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[18],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[18])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[18], dirty, null),
						null
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (default_slot) default_slot.d(detaching);
		}
	};
}

// (36:16) {#each lines as line}
function create_each_block$4(ctx) {
	let div;
	let div_class_value;

	return {
		c() {
			div = element("div");
			attr(div, "class", div_class_value = /*$theme*/ ctx[3].line);
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*$theme*/ 8 && div_class_value !== (div_class_value = /*$theme*/ ctx[3].line)) {
				attr(div, "class", div_class_value);
			}
		},
		d(detaching) {
			if (detaching) {
				detach(div);
			}
		}
	};
}

// (35:12) <svelte:fragment slot="lines">
function create_lines_slot(ctx) {
	let each_1_anchor;
	let each_value = ensure_array_like(/*lines*/ ctx[2]);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block$4(get_each_context$4(ctx, each_value, i));
	}

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert(target, each_1_anchor, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*$theme, lines*/ 12) {
				each_value = ensure_array_like(/*lines*/ ctx[2]);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context$4(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block$4(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		d(detaching) {
			if (detaching) {
				detach(each_1_anchor);
			}

			destroy_each(each_blocks, detaching);
		}
	};
}

function create_fragment$7(ctx) {
	let div1;
	let div0;
	let section;
	let div0_class_value;
	let div1_class_value;
	let current;

	section = new Section({
			props: {
				$$slots: {
					lines: [create_lines_slot],
					default: [create_default_slot$1]
				},
				$$scope: { ctx }
			}
		});

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			create_component(section.$$.fragment);
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[3].content);

			attr(div1, "class", div1_class_value = "" + (/*$theme*/ ctx[3].body + (/*compact*/ ctx[1]
			? ' ' + /*$theme*/ ctx[3].compact
			: '')));
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			mount_component(section, div0, null);
			/*div1_binding*/ ctx[17](div1);
			current = true;
		},
		p(ctx, [dirty]) {
			const section_changes = {};

			if (dirty & /*$$scope, lines, $theme*/ 262156) {
				section_changes.$$scope = { dirty, ctx };
			}

			section.$set(section_changes);

			if (!current || dirty & /*$theme*/ 8 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[3].content)) {
				attr(div0, "class", div0_class_value);
			}

			if (!current || dirty & /*$theme, compact*/ 10 && div1_class_value !== (div1_class_value = "" + (/*$theme*/ ctx[3].body + (/*compact*/ ctx[1]
			? ' ' + /*$theme*/ ctx[3].compact
			: '')))) {
				attr(div1, "class", div1_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(section.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(section.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div1);
			}

			destroy_component(section);
			/*div1_binding*/ ctx[17](null);
		}
	};
}

function instance$7($$self, $$props, $$invalidate) {
	let $slotHeight;
	let $slotDuration;
	let $_slotTimeLimits;
	let $scrollTime;
	let $_viewDates;
	let $_times;
	let $_bodyEl;
	let $theme;
	let { $$slots: slots = {}, $$scope } = $$props;
	let { _bodyEl, _viewDates, _slotTimeLimits, _times, scrollTime, slotDuration, slotHeight, theme } = getContext('state');
	component_subscribe($$self, _bodyEl, value => $$invalidate(21, $_bodyEl = value));
	component_subscribe($$self, _viewDates, value => $$invalidate(14, $_viewDates = value));
	component_subscribe($$self, _slotTimeLimits, value => $$invalidate(20, $_slotTimeLimits = value));
	component_subscribe($$self, _times, value => $$invalidate(15, $_times = value));
	component_subscribe($$self, scrollTime, value => $$invalidate(13, $scrollTime = value));
	component_subscribe($$self, slotDuration, value => $$invalidate(12, $slotDuration = value));
	component_subscribe($$self, slotHeight, value => $$invalidate(19, $slotHeight = value));
	component_subscribe($$self, theme, value => $$invalidate(3, $theme = value));
	let el;
	let compact;
	let lines = [];

	function scrollToTime() {
		$$invalidate(0, el.scrollTop = (($scrollTime.seconds - $_slotTimeLimits.min.seconds) / $slotDuration.seconds - 0.5) * $slotHeight, el);
	}

	function div1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(0, el);
		});
	}

	$$self.$$set = $$props => {
		if ('$$scope' in $$props) $$invalidate(18, $$scope = $$props.$$scope);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*el*/ 1) {
			set_store_value(_bodyEl, $_bodyEl = el, $_bodyEl);
		}

		if ($$self.$$.dirty & /*$slotDuration, $_times*/ 36864) {
			{
				$$invalidate(1, compact = $slotDuration.seconds >= 3600);
				$$invalidate(2, lines.length = $_times.length, lines);
			}
		}

		if ($$self.$$.dirty & /*el, $_viewDates, $scrollTime*/ 24577) {
			if (el) {
				scrollToTime();
			}
		}
	};

	return [
		el,
		compact,
		lines,
		$theme,
		_bodyEl,
		_viewDates,
		_slotTimeLimits,
		_times,
		scrollTime,
		slotDuration,
		slotHeight,
		theme,
		$slotDuration,
		$scrollTime,
		$_viewDates,
		$_times,
		slots,
		div1_binding,
		$$scope
	];
}

class Body extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$7, create_fragment$7, safe_not_equal, {});
	}
}

/* packages/time-grid/src/Event.svelte generated by Svelte v4.2.19 */

function create_fragment$6(ctx) {
	let article;
	let switch_instance0;
	let t0;
	let div;
	let div_class_value;
	let setContent_action;
	let t1;
	let switch_instance1;
	let article_role_value;
	let article_tabindex_value;
	let current;
	let mounted;
	let dispose;
	var switch_value = /*$_interaction*/ ctx[10].resizer;

	function switch_props(ctx, dirty) {
		return {
			props: { start: true, event: /*event*/ ctx[0] }
		};
	}

	if (switch_value) {
		switch_instance0 = construct_svelte_component(switch_value, switch_props(ctx));

		switch_instance0.$on("pointerdown", function () {
			if (is_function(/*createDragHandler*/ ctx[33](/*$_interaction*/ ctx[10], ['y', 'start']))) /*createDragHandler*/ ctx[33](/*$_interaction*/ ctx[10], ['y', 'start']).apply(this, arguments);
		});
	}

	var switch_value_1 = /*$_interaction*/ ctx[10].resizer;

	function switch_props_1(ctx, dirty) {
		return { props: { event: /*event*/ ctx[0] } };
	}

	if (switch_value_1) {
		switch_instance1 = construct_svelte_component(switch_value_1, switch_props_1(ctx));

		switch_instance1.$on("pointerdown", function () {
			if (is_function(/*createDragHandler*/ ctx[33](/*$_interaction*/ ctx[10], ['y', 'end']))) /*createDragHandler*/ ctx[33](/*$_interaction*/ ctx[10], ['y', 'end']).apply(this, arguments);
		});
	}

	return {
		c() {
			article = element("article");
			if (switch_instance0) create_component(switch_instance0.$$.fragment);
			t0 = space();
			div = element("div");
			t1 = space();
			if (switch_instance1) create_component(switch_instance1.$$.fragment);
			attr(div, "class", div_class_value = /*$theme*/ ctx[2].eventBody);
			attr(article, "class", /*classes*/ ctx[4]);
			attr(article, "style", /*style*/ ctx[5]);
			attr(article, "role", article_role_value = /*onclick*/ ctx[7] ? 'button' : undefined);
			attr(article, "tabindex", article_tabindex_value = /*onclick*/ ctx[7] ? 0 : undefined);
		},
		m(target, anchor) {
			insert(target, article, anchor);
			if (switch_instance0) mount_component(switch_instance0, article, null);
			append(article, t0);
			append(article, div);
			append(article, t1);
			if (switch_instance1) mount_component(switch_instance1, article, null);
			/*article_binding*/ ctx[51](article);
			current = true;

			if (!mounted) {
				dispose = [
					action_destroyer(setContent_action = setContent.call(null, div, /*content*/ ctx[6])),
					listen(article, "click", function () {
						if (is_function(/*onclick*/ ctx[7])) /*onclick*/ ctx[7].apply(this, arguments);
					}),
					listen(article, "keydown", function () {
						if (is_function(/*onclick*/ ctx[7] && keyEnter(/*onclick*/ ctx[7]))) (/*onclick*/ ctx[7] && keyEnter(/*onclick*/ ctx[7])).apply(this, arguments);
					}),
					listen(article, "mouseenter", function () {
						if (is_function(/*createHandler*/ ctx[32](/*$eventMouseEnter*/ ctx[8], /*display*/ ctx[1]))) /*createHandler*/ ctx[32](/*$eventMouseEnter*/ ctx[8], /*display*/ ctx[1]).apply(this, arguments);
					}),
					listen(article, "mouseleave", function () {
						if (is_function(/*createHandler*/ ctx[32](/*$eventMouseLeave*/ ctx[9], /*display*/ ctx[1]))) /*createHandler*/ ctx[32](/*$eventMouseLeave*/ ctx[9], /*display*/ ctx[1]).apply(this, arguments);
					}),
					listen(article, "pointerdown", function () {
						if (is_function(!bgEvent(/*display*/ ctx[1]) && !helperEvent(/*display*/ ctx[1]) && /*createDragHandler*/ ctx[33](/*$_interaction*/ ctx[10]))) (!bgEvent(/*display*/ ctx[1]) && !helperEvent(/*display*/ ctx[1]) && /*createDragHandler*/ ctx[33](/*$_interaction*/ ctx[10])).apply(this, arguments);
					})
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty[0] & /*$_interaction*/ 1024 && switch_value !== (switch_value = /*$_interaction*/ ctx[10].resizer)) {
				if (switch_instance0) {
					group_outros();
					const old_component = switch_instance0;

					transition_out(old_component.$$.fragment, 1, 0, () => {
						destroy_component(old_component, 1);
					});

					check_outros();
				}

				if (switch_value) {
					switch_instance0 = construct_svelte_component(switch_value, switch_props(ctx));

					switch_instance0.$on("pointerdown", function () {
						if (is_function(/*createDragHandler*/ ctx[33](/*$_interaction*/ ctx[10], ['y', 'start']))) /*createDragHandler*/ ctx[33](/*$_interaction*/ ctx[10], ['y', 'start']).apply(this, arguments);
					});

					create_component(switch_instance0.$$.fragment);
					transition_in(switch_instance0.$$.fragment, 1);
					mount_component(switch_instance0, article, t0);
				} else {
					switch_instance0 = null;
				}
			} else if (switch_value) {
				const switch_instance0_changes = {};
				if (dirty[0] & /*event*/ 1) switch_instance0_changes.event = /*event*/ ctx[0];
				switch_instance0.$set(switch_instance0_changes);
			}

			if (!current || dirty[0] & /*$theme*/ 4 && div_class_value !== (div_class_value = /*$theme*/ ctx[2].eventBody)) {
				attr(div, "class", div_class_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty[0] & /*content*/ 64) setContent_action.update.call(null, /*content*/ ctx[6]);

			if (dirty[0] & /*$_interaction*/ 1024 && switch_value_1 !== (switch_value_1 = /*$_interaction*/ ctx[10].resizer)) {
				if (switch_instance1) {
					group_outros();
					const old_component = switch_instance1;

					transition_out(old_component.$$.fragment, 1, 0, () => {
						destroy_component(old_component, 1);
					});

					check_outros();
				}

				if (switch_value_1) {
					switch_instance1 = construct_svelte_component(switch_value_1, switch_props_1(ctx));

					switch_instance1.$on("pointerdown", function () {
						if (is_function(/*createDragHandler*/ ctx[33](/*$_interaction*/ ctx[10], ['y', 'end']))) /*createDragHandler*/ ctx[33](/*$_interaction*/ ctx[10], ['y', 'end']).apply(this, arguments);
					});

					create_component(switch_instance1.$$.fragment);
					transition_in(switch_instance1.$$.fragment, 1);
					mount_component(switch_instance1, article, null);
				} else {
					switch_instance1 = null;
				}
			} else if (switch_value_1) {
				const switch_instance1_changes = {};
				if (dirty[0] & /*event*/ 1) switch_instance1_changes.event = /*event*/ ctx[0];
				switch_instance1.$set(switch_instance1_changes);
			}

			if (!current || dirty[0] & /*classes*/ 16) {
				attr(article, "class", /*classes*/ ctx[4]);
			}

			if (!current || dirty[0] & /*style*/ 32) {
				attr(article, "style", /*style*/ ctx[5]);
			}

			if (!current || dirty[0] & /*onclick*/ 128 && article_role_value !== (article_role_value = /*onclick*/ ctx[7] ? 'button' : undefined)) {
				attr(article, "role", article_role_value);
			}

			if (!current || dirty[0] & /*onclick*/ 128 && article_tabindex_value !== (article_tabindex_value = /*onclick*/ ctx[7] ? 0 : undefined)) {
				attr(article, "tabindex", article_tabindex_value);
			}
		},
		i(local) {
			if (current) return;
			if (switch_instance0) transition_in(switch_instance0.$$.fragment, local);
			if (switch_instance1) transition_in(switch_instance1.$$.fragment, local);
			current = true;
		},
		o(local) {
			if (switch_instance0) transition_out(switch_instance0.$$.fragment, local);
			if (switch_instance1) transition_out(switch_instance1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(article);
			}

			if (switch_instance0) destroy_component(switch_instance0);
			if (switch_instance1) destroy_component(switch_instance1);
			/*article_binding*/ ctx[51](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance$6($$self, $$props, $$invalidate) {
	let $eventClick;
	let $_view;
	let $eventAllUpdated;
	let $eventDidMount;
	let $_intlEventTime;
	let $theme;
	let $eventContent;
	let $displayEventEnd;
	let $eventClassNames;
	let $_iClasses;
	let $slotEventOverlap;
	let $eventTextColor;
	let $resources;
	let $eventColor;
	let $eventBackgroundColor;
	let $slotHeight;
	let $_slotTimeLimits;
	let $slotDuration;
	let $eventMouseEnter;
	let $eventMouseLeave;
	let $_interaction;
	let { date } = $$props;
	let { chunk } = $$props;
	let { displayEventEnd, eventAllUpdated, eventBackgroundColor, eventTextColor, eventColor, eventContent, eventClick, eventDidMount, eventClassNames, eventMouseEnter, eventMouseLeave, slotEventOverlap, slotDuration, slotHeight, resources, theme, _view, _intlEventTime, _interaction, _iClasses, _slotTimeLimits, _tasks } = getContext('state');
	component_subscribe($$self, displayEventEnd, value => $$invalidate(40, $displayEventEnd = value));
	component_subscribe($$self, eventAllUpdated, value => $$invalidate(53, $eventAllUpdated = value));
	component_subscribe($$self, eventBackgroundColor, value => $$invalidate(47, $eventBackgroundColor = value));
	component_subscribe($$self, eventTextColor, value => $$invalidate(44, $eventTextColor = value));
	component_subscribe($$self, eventColor, value => $$invalidate(46, $eventColor = value));
	component_subscribe($$self, eventContent, value => $$invalidate(39, $eventContent = value));
	component_subscribe($$self, eventClick, value => $$invalidate(36, $eventClick = value));
	component_subscribe($$self, eventDidMount, value => $$invalidate(54, $eventDidMount = value));
	component_subscribe($$self, eventClassNames, value => $$invalidate(41, $eventClassNames = value));
	component_subscribe($$self, eventMouseEnter, value => $$invalidate(8, $eventMouseEnter = value));
	component_subscribe($$self, eventMouseLeave, value => $$invalidate(9, $eventMouseLeave = value));
	component_subscribe($$self, slotEventOverlap, value => $$invalidate(43, $slotEventOverlap = value));
	component_subscribe($$self, slotDuration, value => $$invalidate(50, $slotDuration = value));
	component_subscribe($$self, slotHeight, value => $$invalidate(48, $slotHeight = value));
	component_subscribe($$self, resources, value => $$invalidate(45, $resources = value));
	component_subscribe($$self, theme, value => $$invalidate(2, $theme = value));
	component_subscribe($$self, _view, value => $$invalidate(37, $_view = value));
	component_subscribe($$self, _intlEventTime, value => $$invalidate(38, $_intlEventTime = value));
	component_subscribe($$self, _interaction, value => $$invalidate(10, $_interaction = value));
	component_subscribe($$self, _iClasses, value => $$invalidate(42, $_iClasses = value));
	component_subscribe($$self, _slotTimeLimits, value => $$invalidate(49, $_slotTimeLimits = value));
	let el;
	let event;
	let display;
	let classes;
	let style;
	let content;
	let timeText;
	let onclick;

	onMount(() => {
		if (isFunction($eventDidMount)) {
			$eventDidMount({
				event: toEventWithLocalDates(event),
				timeText,
				el,
				view: toViewWithLocalDates($_view)
			});
		}
	});

	afterUpdate(() => {
		if (isFunction($eventAllUpdated) && !helperEvent(display)) {
			task(() => $eventAllUpdated({ view: toViewWithLocalDates($_view) }), 'eau', _tasks);
		}
	});

	function createHandler(fn, display) {
		return !helperEvent(display) && isFunction(fn)
		? jsEvent => fn({
				event: toEventWithLocalDates(event),
				el,
				jsEvent,
				view: toViewWithLocalDates($_view)
			})
		: undefined;
	}

	function createDragHandler(interaction, resize) {
		return interaction.action
		? jsEvent => interaction.action.drag(event, jsEvent, resize, undefined, undefined, chunk.zeroDuration)
		: undefined;
	}

	function article_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(3, el);
		});
	}

	$$self.$$set = $$props => {
		if ('date' in $$props) $$invalidate(34, date = $$props.date);
		if ('chunk' in $$props) $$invalidate(35, chunk = $$props.chunk);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty[1] & /*chunk*/ 16) {
			$$invalidate(0, event = chunk.event);
		}

		if ($$self.$$.dirty[0] & /*event, style, display, $theme*/ 39 | $$self.$$.dirty[1] & /*$slotDuration, $_slotTimeLimits, chunk, date, $slotHeight, $resources, $eventBackgroundColor, $eventColor, $eventTextColor, $slotEventOverlap, $_iClasses, $eventClassNames, $_view*/ 1047640) {
			{
				$$invalidate(1, display = event.display);

				// Style
				let step = $slotDuration.seconds;

				let offset = $_slotTimeLimits.min.seconds;
				let start = (chunk.start - date) / 1000;
				let end = (chunk.end - date) / 1000;
				let top = (start - offset) / step * $slotHeight;
				let height = max((end - start) / step * $slotHeight, $slotHeight);
				let maxHeight = ($_slotTimeLimits.max.seconds - start) / step * $slotHeight;
				let bgColor = event.backgroundColor || resourceBackgroundColor(event, $resources) || $eventBackgroundColor || $eventColor;
				let txtColor = event.textColor || resourceTextColor(event, $resources) || $eventTextColor;
				$$invalidate(5, style = `top:${top}px;` + `min-height:${height}px;` + `height:${height}px;` + `max-height:${maxHeight}px;`);

				if (bgColor) {
					$$invalidate(5, style += `background-color:${bgColor};`);
				}

				if (txtColor) {
					$$invalidate(5, style += `color:${txtColor};`);
				}

				if (!bgEvent(display) && !helperEvent(display) || ghostEvent(display)) {
					$$invalidate(5, style += `z-index:${chunk.column + 1};` + `left:${100 / chunk.group.columns.length * chunk.column}%;` + `width:${100 / chunk.group.columns.length * ($slotEventOverlap
					? 0.5 * (1 + chunk.group.columns.length - chunk.column)
					: 1)}%;`);
				}

				$$invalidate(5, style += event.styles.join(';'));

				// Class
				$$invalidate(4, classes = [
					bgEvent(display) ? $theme.bgEvent : $theme.event,
					...$_iClasses([], event),
					...createEventClasses($eventClassNames, event, $_view)
				].join(' '));
			}
		}

		if ($$self.$$.dirty[0] & /*$theme*/ 4 | $$self.$$.dirty[1] & /*chunk, $displayEventEnd, $eventContent, $_intlEventTime, $_view*/ 976) {
			// Content
			$$invalidate(6, [timeText, content] = createEventContent(chunk, $displayEventEnd, $eventContent, $theme, $_intlEventTime, $_view), content);
		}

		if ($$self.$$.dirty[0] & /*display*/ 2 | $$self.$$.dirty[1] & /*$eventClick*/ 32) {
			// Onclick handler
			$$invalidate(7, onclick = !bgEvent(display) && createHandler($eventClick, display));
		}
	};

	return [
		event,
		display,
		$theme,
		el,
		classes,
		style,
		content,
		onclick,
		$eventMouseEnter,
		$eventMouseLeave,
		$_interaction,
		displayEventEnd,
		eventAllUpdated,
		eventBackgroundColor,
		eventTextColor,
		eventColor,
		eventContent,
		eventClick,
		eventDidMount,
		eventClassNames,
		eventMouseEnter,
		eventMouseLeave,
		slotEventOverlap,
		slotDuration,
		slotHeight,
		resources,
		theme,
		_view,
		_intlEventTime,
		_interaction,
		_iClasses,
		_slotTimeLimits,
		createHandler,
		createDragHandler,
		date,
		chunk,
		$eventClick,
		$_view,
		$_intlEventTime,
		$eventContent,
		$displayEventEnd,
		$eventClassNames,
		$_iClasses,
		$slotEventOverlap,
		$eventTextColor,
		$resources,
		$eventColor,
		$eventBackgroundColor,
		$slotHeight,
		$_slotTimeLimits,
		$slotDuration,
		article_binding
	];
}

let Event$1 = class Event extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$6, create_fragment$6, safe_not_equal, { date: 34, chunk: 35 }, null, [-1, -1]);
	}
};

/* packages/time-grid/src/NowIndicator.svelte generated by Svelte v4.2.19 */

function create_fragment$5(ctx) {
	let div;
	let div_class_value;

	return {
		c() {
			div = element("div");
			attr(div, "class", div_class_value = /*$theme*/ ctx[1].nowIndicator);
			set_style(div, "top", /*top*/ ctx[0] + "px");
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		p(ctx, [dirty]) {
			if (dirty & /*$theme*/ 2 && div_class_value !== (div_class_value = /*$theme*/ ctx[1].nowIndicator)) {
				attr(div, "class", div_class_value);
			}

			if (dirty & /*top*/ 1) {
				set_style(div, "top", /*top*/ ctx[0] + "px");
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) {
				detach(div);
			}
		}
	};
}

function instance$5($$self, $$props, $$invalidate) {
	let $slotHeight;
	let $_slotTimeLimits;
	let $slotDuration;
	let $_today;
	let $_now;
	let $theme;
	let { slotDuration, slotHeight, theme, _now, _today, _slotTimeLimits } = getContext('state');
	component_subscribe($$self, slotDuration, value => $$invalidate(11, $slotDuration = value));
	component_subscribe($$self, slotHeight, value => $$invalidate(9, $slotHeight = value));
	component_subscribe($$self, theme, value => $$invalidate(1, $theme = value));
	component_subscribe($$self, _now, value => $$invalidate(13, $_now = value));
	component_subscribe($$self, _today, value => $$invalidate(12, $_today = value));
	component_subscribe($$self, _slotTimeLimits, value => $$invalidate(10, $_slotTimeLimits = value));
	let start;
	let top = 0;

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$_now, $_today*/ 12288) {
			$$invalidate(8, start = ($_now - $_today) / 1000 / 60);
		}

		if ($$self.$$.dirty & /*$slotDuration, $_slotTimeLimits, start, $slotHeight*/ 3840) {
			{
				// Style
				let step = $slotDuration.seconds / 60;

				let offset = $_slotTimeLimits.min.seconds / 60;
				$$invalidate(0, top = (start - offset) / step * $slotHeight);
			}
		}
	};

	return [
		top,
		$theme,
		slotDuration,
		slotHeight,
		theme,
		_now,
		_today,
		_slotTimeLimits,
		start,
		$slotHeight,
		$_slotTimeLimits,
		$slotDuration,
		$_today,
		$_now
	];
}

class NowIndicator extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$5, create_fragment$5, safe_not_equal, {});
	}
}

/* packages/time-grid/src/Day.svelte generated by Svelte v4.2.19 */

function get_each_context$3(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[40] = list[i];
	return child_ctx;
}

function get_each_context_1$2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[40] = list[i];
	return child_ctx;
}

// (84:8) {#if !disabled}
function create_if_block_4(ctx) {
	let each_blocks = [];
	let each_1_lookup = new Map();
	let each_1_anchor;
	let current;
	let each_value_1 = ensure_array_like(/*bgChunks*/ ctx[3]);
	const get_key = ctx => /*chunk*/ ctx[40].event;

	for (let i = 0; i < each_value_1.length; i += 1) {
		let child_ctx = get_each_context_1$2(ctx, each_value_1, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block_1$2(key, child_ctx));
	}

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert(target, each_1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty[0] & /*date, bgChunks*/ 9) {
				each_value_1 = ensure_array_like(/*bgChunks*/ ctx[3]);
				group_outros();
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value_1, each_1_lookup, each_1_anchor.parentNode, outro_and_destroy_block, create_each_block_1$2, each_1_anchor, get_each_context_1$2);
				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value_1.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(each_1_anchor);
			}

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d(detaching);
			}
		}
	};
}

// (85:12) {#each bgChunks as chunk (chunk.event)}
function create_each_block_1$2(key_1, ctx) {
	let first;
	let event;
	let current;

	event = new Event$1({
			props: {
				date: /*date*/ ctx[0],
				chunk: /*chunk*/ ctx[40]
			}
		});

	return {
		key: key_1,
		first: null,
		c() {
			first = empty();
			create_component(event.$$.fragment);
			this.first = first;
		},
		m(target, anchor) {
			insert(target, first, anchor);
			mount_component(event, target, anchor);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const event_changes = {};
			if (dirty[0] & /*date*/ 1) event_changes.date = /*date*/ ctx[0];
			if (dirty[0] & /*bgChunks*/ 8) event_changes.chunk = /*chunk*/ ctx[40];
			event.$set(event_changes);
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(first);
			}

			destroy_component(event, detaching);
		}
	};
}

// (91:8) {#if !disabled}
function create_if_block_1$1(ctx) {
	let t0;
	let each_blocks = [];
	let each_1_lookup = new Map();
	let t1;
	let if_block1_anchor;
	let current;
	let if_block0 = /*iChunks*/ ctx[5][1] && create_if_block_3(ctx);
	let each_value = ensure_array_like(/*chunks*/ ctx[2]);
	const get_key = ctx => /*chunk*/ ctx[40].event;

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context$3(ctx, each_value, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block$3(key, child_ctx));
	}

	let if_block1 = /*iChunks*/ ctx[5][0] && !/*iChunks*/ ctx[5][0].event.allDay && create_if_block_2$1(ctx);

	return {
		c() {
			if (if_block0) if_block0.c();
			t0 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t1 = space();
			if (if_block1) if_block1.c();
			if_block1_anchor = empty();
		},
		m(target, anchor) {
			if (if_block0) if_block0.m(target, anchor);
			insert(target, t0, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert(target, t1, anchor);
			if (if_block1) if_block1.m(target, anchor);
			insert(target, if_block1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (/*iChunks*/ ctx[5][1]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);

					if (dirty[0] & /*iChunks*/ 32) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_3(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(t0.parentNode, t0);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (dirty[0] & /*date, chunks*/ 5) {
				each_value = ensure_array_like(/*chunks*/ ctx[2]);
				group_outros();
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value, each_1_lookup, t1.parentNode, outro_and_destroy_block, create_each_block$3, t1, get_each_context$3);
				check_outros();
			}

			if (/*iChunks*/ ctx[5][0] && !/*iChunks*/ ctx[5][0].event.allDay) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty[0] & /*iChunks*/ 32) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_2$1(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(if_block1_anchor.parentNode, if_block1_anchor);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			transition_in(if_block1);
			current = true;
		},
		o(local) {
			transition_out(if_block0);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			transition_out(if_block1);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(t0);
				detach(t1);
				detach(if_block1_anchor);
			}

			if (if_block0) if_block0.d(detaching);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d(detaching);
			}

			if (if_block1) if_block1.d(detaching);
		}
	};
}

// (93:12) {#if iChunks[1]}
function create_if_block_3(ctx) {
	let event;
	let current;

	event = new Event$1({
			props: {
				date: /*date*/ ctx[0],
				chunk: /*iChunks*/ ctx[5][1]
			}
		});

	return {
		c() {
			create_component(event.$$.fragment);
		},
		m(target, anchor) {
			mount_component(event, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const event_changes = {};
			if (dirty[0] & /*date*/ 1) event_changes.date = /*date*/ ctx[0];
			if (dirty[0] & /*iChunks*/ 32) event_changes.chunk = /*iChunks*/ ctx[5][1];
			event.$set(event_changes);
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(event, detaching);
		}
	};
}

// (96:12) {#each chunks as chunk (chunk.event)}
function create_each_block$3(key_1, ctx) {
	let first;
	let event;
	let current;

	event = new Event$1({
			props: {
				date: /*date*/ ctx[0],
				chunk: /*chunk*/ ctx[40]
			}
		});

	return {
		key: key_1,
		first: null,
		c() {
			first = empty();
			create_component(event.$$.fragment);
			this.first = first;
		},
		m(target, anchor) {
			insert(target, first, anchor);
			mount_component(event, target, anchor);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const event_changes = {};
			if (dirty[0] & /*date*/ 1) event_changes.date = /*date*/ ctx[0];
			if (dirty[0] & /*chunks*/ 4) event_changes.chunk = /*chunk*/ ctx[40];
			event.$set(event_changes);
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(first);
			}

			destroy_component(event, detaching);
		}
	};
}

// (100:12) {#if iChunks[0] && !iChunks[0].event.allDay}
function create_if_block_2$1(ctx) {
	let event;
	let current;

	event = new Event$1({
			props: {
				date: /*date*/ ctx[0],
				chunk: /*iChunks*/ ctx[5][0]
			}
		});

	return {
		c() {
			create_component(event.$$.fragment);
		},
		m(target, anchor) {
			mount_component(event, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const event_changes = {};
			if (dirty[0] & /*date*/ 1) event_changes.date = /*date*/ ctx[0];
			if (dirty[0] & /*iChunks*/ 32) event_changes.chunk = /*iChunks*/ ctx[5][0];
			event.$set(event_changes);
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(event, detaching);
		}
	};
}

// (107:8) {#if $nowIndicator && isToday && !disabled}
function create_if_block$2(ctx) {
	let nowindicator;
	let current;
	nowindicator = new NowIndicator({});

	return {
		c() {
			create_component(nowindicator.$$.fragment);
		},
		m(target, anchor) {
			mount_component(nowindicator, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(nowindicator.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(nowindicator.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(nowindicator, detaching);
		}
	};
}

function create_fragment$4(ctx) {
	let div3;
	let div0;
	let div0_class_value;
	let t0;
	let div1;
	let div1_class_value;
	let t1;
	let div2;
	let div2_class_value;
	let div3_class_value;
	let current;
	let mounted;
	let dispose;
	let if_block0 = !/*disabled*/ ctx[4] && create_if_block_4(ctx);
	let if_block1 = !/*disabled*/ ctx[4] && create_if_block_1$1(ctx);
	let if_block2 = /*$nowIndicator*/ ctx[10] && /*isToday*/ ctx[6] && !/*disabled*/ ctx[4] && create_if_block$2();

	return {
		c() {
			div3 = element("div");
			div0 = element("div");
			if (if_block0) if_block0.c();
			t0 = space();
			div1 = element("div");
			if (if_block1) if_block1.c();
			t1 = space();
			div2 = element("div");
			if (if_block2) if_block2.c();
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[8].bgEvents);
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[8].events);
			attr(div2, "class", div2_class_value = /*$theme*/ ctx[8].extra);

			attr(div3, "class", div3_class_value = "" + (/*$theme*/ ctx[8].day + " " + /*$theme*/ ctx[8].weekdays?.[/*date*/ ctx[0].getUTCDay()] + (/*isToday*/ ctx[6] ? ' ' + /*$theme*/ ctx[8].today : '') + (/*highlight*/ ctx[7]
			? ' ' + /*$theme*/ ctx[8].highlight
			: '') + (/*disabled*/ ctx[4]
			? ' ' + /*$theme*/ ctx[8].disabled
			: '')));

			attr(div3, "role", "cell");
		},
		m(target, anchor) {
			insert(target, div3, anchor);
			append(div3, div0);
			if (if_block0) if_block0.m(div0, null);
			append(div3, t0);
			append(div3, div1);
			if (if_block1) if_block1.m(div1, null);
			append(div3, t1);
			append(div3, div2);
			if (if_block2) if_block2.m(div2, null);
			/*div3_binding*/ ctx[36](div3);
			current = true;

			if (!mounted) {
				dispose = listen(div3, "pointerdown", function () {
					if (is_function(!/*disabled*/ ctx[4]
					? /*$_interaction*/ ctx[9].action?.select
					: undefined)) (!/*disabled*/ ctx[4]
					? /*$_interaction*/ ctx[9].action?.select
					: undefined).apply(this, arguments);
				});

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (!/*disabled*/ ctx[4]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);

					if (dirty[0] & /*disabled*/ 16) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_4(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(div0, null);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (!current || dirty[0] & /*$theme*/ 256 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[8].bgEvents)) {
				attr(div0, "class", div0_class_value);
			}

			if (!/*disabled*/ ctx[4]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty[0] & /*disabled*/ 16) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_1$1(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div1, null);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (!current || dirty[0] & /*$theme*/ 256 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[8].events)) {
				attr(div1, "class", div1_class_value);
			}

			if (/*$nowIndicator*/ ctx[10] && /*isToday*/ ctx[6] && !/*disabled*/ ctx[4]) {
				if (if_block2) {
					if (dirty[0] & /*$nowIndicator, isToday, disabled*/ 1104) {
						transition_in(if_block2, 1);
					}
				} else {
					if_block2 = create_if_block$2();
					if_block2.c();
					transition_in(if_block2, 1);
					if_block2.m(div2, null);
				}
			} else if (if_block2) {
				group_outros();

				transition_out(if_block2, 1, 1, () => {
					if_block2 = null;
				});

				check_outros();
			}

			if (!current || dirty[0] & /*$theme*/ 256 && div2_class_value !== (div2_class_value = /*$theme*/ ctx[8].extra)) {
				attr(div2, "class", div2_class_value);
			}

			if (!current || dirty[0] & /*$theme, date, isToday, highlight, disabled*/ 465 && div3_class_value !== (div3_class_value = "" + (/*$theme*/ ctx[8].day + " " + /*$theme*/ ctx[8].weekdays?.[/*date*/ ctx[0].getUTCDay()] + (/*isToday*/ ctx[6] ? ' ' + /*$theme*/ ctx[8].today : '') + (/*highlight*/ ctx[7]
			? ' ' + /*$theme*/ ctx[8].highlight
			: '') + (/*disabled*/ ctx[4]
			? ' ' + /*$theme*/ ctx[8].disabled
			: '')))) {
				attr(div3, "class", div3_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			transition_in(if_block1);
			transition_in(if_block2);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			transition_out(if_block1);
			transition_out(if_block2);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div3);
			}

			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
			/*div3_binding*/ ctx[36](null);
			mounted = false;
			dispose();
		}
	};
}

function instance$4($$self, $$props, $$invalidate) {
	let $slotHeight;
	let $slotDuration;
	let $_slotTimeLimits;
	let $_iEvents;
	let $_events;
	let $resources;
	let $filterEventsWithResources;
	let $validRange;
	let $highlightedDates;
	let $_today;
	let $theme;
	let $_interaction;
	let $nowIndicator;
	let { date } = $$props;
	let { resource = undefined } = $$props;
	let { _events, _iEvents, highlightedDates, nowIndicator, slotDuration, slotHeight, filterEventsWithResources, theme, resources, validRange, _interaction, _today, _slotTimeLimits } = getContext('state');
	component_subscribe($$self, _events, value => $$invalidate(30, $_events = value));
	component_subscribe($$self, _iEvents, value => $$invalidate(29, $_iEvents = value));
	component_subscribe($$self, highlightedDates, value => $$invalidate(34, $highlightedDates = value));
	component_subscribe($$self, nowIndicator, value => $$invalidate(10, $nowIndicator = value));
	component_subscribe($$self, slotDuration, value => $$invalidate(38, $slotDuration = value));
	component_subscribe($$self, slotHeight, value => $$invalidate(37, $slotHeight = value));
	component_subscribe($$self, filterEventsWithResources, value => $$invalidate(32, $filterEventsWithResources = value));
	component_subscribe($$self, theme, value => $$invalidate(8, $theme = value));
	component_subscribe($$self, resources, value => $$invalidate(31, $resources = value));
	component_subscribe($$self, validRange, value => $$invalidate(33, $validRange = value));
	component_subscribe($$self, _interaction, value => $$invalidate(9, $_interaction = value));
	component_subscribe($$self, _today, value => $$invalidate(35, $_today = value));
	component_subscribe($$self, _slotTimeLimits, value => $$invalidate(28, $_slotTimeLimits = value));
	let el;
	let chunks, bgChunks, iChunks = [];
	let isToday, highlight, disabled;
	let resourceFilter;
	let start, end;

	function dateFromPoint(x, y) {
		y -= rect(el).top;

		return {
			allDay: false,
			date: addDuration(addDuration(cloneDate(date), $_slotTimeLimits.min), $slotDuration, floor(y / $slotHeight)),
			resource,
			dayEl: el,
			disabled
		};
	}

	function div3_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(1, el);
		});
	}

	$$self.$$set = $$props => {
		if ('date' in $$props) $$invalidate(0, date = $$props.date);
		if ('resource' in $$props) $$invalidate(24, resource = $$props.resource);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*date*/ 1 | $$self.$$.dirty[1] & /*$_today*/ 16) {
			$$invalidate(6, isToday = datesEqual(date, $_today));
		}

		if ($$self.$$.dirty[0] & /*date*/ 1 | $$self.$$.dirty[1] & /*$highlightedDates*/ 8) {
			$$invalidate(7, highlight = $highlightedDates.some(d => datesEqual(d, date)));
		}

		if ($$self.$$.dirty[0] & /*date*/ 1 | $$self.$$.dirty[1] & /*$validRange*/ 4) {
			$$invalidate(4, disabled = outsideRange(date, $validRange));
		}

		if ($$self.$$.dirty[0] & /*disabled, date, $_slotTimeLimits*/ 268435473) {
			if (!disabled) {
				$$invalidate(26, start = addDuration(cloneDate(date), $_slotTimeLimits.min));
				$$invalidate(27, end = addDuration(cloneDate(date), $_slotTimeLimits.max));
			}
		}

		if ($$self.$$.dirty[0] & /*resource*/ 16777216 | $$self.$$.dirty[1] & /*$filterEventsWithResources, $resources*/ 3) {
			$$invalidate(25, resourceFilter = resource ?? ($filterEventsWithResources ? $resources : undefined));
		}

		if ($$self.$$.dirty[0] & /*disabled, $_events, start, end, resourceFilter, bgChunks, chunks*/ 1308622876) {
			if (!disabled) {
				$$invalidate(2, chunks = []);
				$$invalidate(3, bgChunks = []);

				for (let event of $_events) {
					if ((!event.allDay || bgEvent(event.display)) && eventIntersects(event, start, end, resourceFilter)) {
						let chunk = createEventChunk(event, start, end);

						switch (event.display) {
							case 'background':
								bgChunks.push(chunk);
								break;
							default:
								chunks.push(chunk);
						}
					}
				}

				groupEventChunks(chunks);
			}
		}

		if ($$self.$$.dirty[0] & /*disabled, $_iEvents, start, end, resource*/ 754974736) {
			if (!disabled) {
				$$invalidate(5, iChunks = $_iEvents.map(event => event && eventIntersects(event, start, end, resource)
				? createEventChunk(event, start, end)
				: null));
			}
		}

		if ($$self.$$.dirty[0] & /*el*/ 2) {
			if (el) {
				setPayload(el, dateFromPoint);
			}
		}
	};

	return [
		date,
		el,
		chunks,
		bgChunks,
		disabled,
		iChunks,
		isToday,
		highlight,
		$theme,
		$_interaction,
		$nowIndicator,
		_events,
		_iEvents,
		highlightedDates,
		nowIndicator,
		slotDuration,
		slotHeight,
		filterEventsWithResources,
		theme,
		resources,
		validRange,
		_interaction,
		_today,
		_slotTimeLimits,
		resource,
		resourceFilter,
		start,
		end,
		$_slotTimeLimits,
		$_iEvents,
		$_events,
		$resources,
		$filterEventsWithResources,
		$validRange,
		$highlightedDates,
		$_today,
		div3_binding
	];
}

let Day$1 = class Day extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$4, create_fragment$4, safe_not_equal, { date: 0, resource: 24 }, null, [-1, -1]);
	}
};

/* packages/time-grid/src/all-day/Event.svelte generated by Svelte v4.2.19 */

function create_fragment$3(ctx) {
	let article;
	let switch_instance0;
	let t0;
	let div;
	let div_class_value;
	let setContent_action;
	let t1;
	let switch_instance1;
	let article_role_value;
	let article_tabindex_value;
	let current;
	let mounted;
	let dispose;
	var switch_value = /*$_interaction*/ ctx[10].resizer;

	function switch_props(ctx, dirty) {
		return {
			props: { start: true, event: /*event*/ ctx[0] }
		};
	}

	if (switch_value) {
		switch_instance0 = construct_svelte_component(switch_value, switch_props(ctx));

		switch_instance0.$on("pointerdown", function () {
			if (is_function(/*createDragHandler*/ ctx[29](/*$_interaction*/ ctx[10], ['x', 'start']))) /*createDragHandler*/ ctx[29](/*$_interaction*/ ctx[10], ['x', 'start']).apply(this, arguments);
		});
	}

	var switch_value_1 = /*$_interaction*/ ctx[10].resizer;

	function switch_props_1(ctx, dirty) {
		return { props: { event: /*event*/ ctx[0] } };
	}

	if (switch_value_1) {
		switch_instance1 = construct_svelte_component(switch_value_1, switch_props_1(ctx));

		switch_instance1.$on("pointerdown", function () {
			if (is_function(/*createDragHandler*/ ctx[29](/*$_interaction*/ ctx[10], ['x', 'end']))) /*createDragHandler*/ ctx[29](/*$_interaction*/ ctx[10], ['x', 'end']).apply(this, arguments);
		});
	}

	return {
		c() {
			article = element("article");
			if (switch_instance0) create_component(switch_instance0.$$.fragment);
			t0 = space();
			div = element("div");
			t1 = space();
			if (switch_instance1) create_component(switch_instance1.$$.fragment);
			attr(div, "class", div_class_value = /*$theme*/ ctx[2].eventBody);
			attr(article, "class", /*classes*/ ctx[4]);
			attr(article, "style", /*style*/ ctx[5]);
			attr(article, "role", article_role_value = /*onclick*/ ctx[7] ? 'button' : undefined);
			attr(article, "tabindex", article_tabindex_value = /*onclick*/ ctx[7] ? 0 : undefined);
		},
		m(target, anchor) {
			insert(target, article, anchor);
			if (switch_instance0) mount_component(switch_instance0, article, null);
			append(article, t0);
			append(article, div);
			append(article, t1);
			if (switch_instance1) mount_component(switch_instance1, article, null);
			/*article_binding*/ ctx[45](article);
			current = true;

			if (!mounted) {
				dispose = [
					action_destroyer(setContent_action = setContent.call(null, div, /*content*/ ctx[6])),
					listen(article, "click", function () {
						if (is_function(/*onclick*/ ctx[7])) /*onclick*/ ctx[7].apply(this, arguments);
					}),
					listen(article, "keydown", function () {
						if (is_function(/*onclick*/ ctx[7] && keyEnter(/*onclick*/ ctx[7]))) (/*onclick*/ ctx[7] && keyEnter(/*onclick*/ ctx[7])).apply(this, arguments);
					}),
					listen(article, "mouseenter", function () {
						if (is_function(/*createHandler*/ ctx[28](/*$eventMouseEnter*/ ctx[8], /*display*/ ctx[1]))) /*createHandler*/ ctx[28](/*$eventMouseEnter*/ ctx[8], /*display*/ ctx[1]).apply(this, arguments);
					}),
					listen(article, "mouseleave", function () {
						if (is_function(/*createHandler*/ ctx[28](/*$eventMouseLeave*/ ctx[9], /*display*/ ctx[1]))) /*createHandler*/ ctx[28](/*$eventMouseLeave*/ ctx[9], /*display*/ ctx[1]).apply(this, arguments);
					}),
					listen(article, "pointerdown", function () {
						if (is_function(!helperEvent(/*display*/ ctx[1]) && /*createDragHandler*/ ctx[29](/*$_interaction*/ ctx[10]))) (!helperEvent(/*display*/ ctx[1]) && /*createDragHandler*/ ctx[29](/*$_interaction*/ ctx[10])).apply(this, arguments);
					})
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty[0] & /*$_interaction*/ 1024 && switch_value !== (switch_value = /*$_interaction*/ ctx[10].resizer)) {
				if (switch_instance0) {
					group_outros();
					const old_component = switch_instance0;

					transition_out(old_component.$$.fragment, 1, 0, () => {
						destroy_component(old_component, 1);
					});

					check_outros();
				}

				if (switch_value) {
					switch_instance0 = construct_svelte_component(switch_value, switch_props(ctx));

					switch_instance0.$on("pointerdown", function () {
						if (is_function(/*createDragHandler*/ ctx[29](/*$_interaction*/ ctx[10], ['x', 'start']))) /*createDragHandler*/ ctx[29](/*$_interaction*/ ctx[10], ['x', 'start']).apply(this, arguments);
					});

					create_component(switch_instance0.$$.fragment);
					transition_in(switch_instance0.$$.fragment, 1);
					mount_component(switch_instance0, article, t0);
				} else {
					switch_instance0 = null;
				}
			} else if (switch_value) {
				const switch_instance0_changes = {};
				if (dirty[0] & /*event*/ 1) switch_instance0_changes.event = /*event*/ ctx[0];
				switch_instance0.$set(switch_instance0_changes);
			}

			if (!current || dirty[0] & /*$theme*/ 4 && div_class_value !== (div_class_value = /*$theme*/ ctx[2].eventBody)) {
				attr(div, "class", div_class_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty[0] & /*content*/ 64) setContent_action.update.call(null, /*content*/ ctx[6]);

			if (dirty[0] & /*$_interaction*/ 1024 && switch_value_1 !== (switch_value_1 = /*$_interaction*/ ctx[10].resizer)) {
				if (switch_instance1) {
					group_outros();
					const old_component = switch_instance1;

					transition_out(old_component.$$.fragment, 1, 0, () => {
						destroy_component(old_component, 1);
					});

					check_outros();
				}

				if (switch_value_1) {
					switch_instance1 = construct_svelte_component(switch_value_1, switch_props_1(ctx));

					switch_instance1.$on("pointerdown", function () {
						if (is_function(/*createDragHandler*/ ctx[29](/*$_interaction*/ ctx[10], ['x', 'end']))) /*createDragHandler*/ ctx[29](/*$_interaction*/ ctx[10], ['x', 'end']).apply(this, arguments);
					});

					create_component(switch_instance1.$$.fragment);
					transition_in(switch_instance1.$$.fragment, 1);
					mount_component(switch_instance1, article, null);
				} else {
					switch_instance1 = null;
				}
			} else if (switch_value_1) {
				const switch_instance1_changes = {};
				if (dirty[0] & /*event*/ 1) switch_instance1_changes.event = /*event*/ ctx[0];
				switch_instance1.$set(switch_instance1_changes);
			}

			if (!current || dirty[0] & /*classes*/ 16) {
				attr(article, "class", /*classes*/ ctx[4]);
			}

			if (!current || dirty[0] & /*style*/ 32) {
				attr(article, "style", /*style*/ ctx[5]);
			}

			if (!current || dirty[0] & /*onclick*/ 128 && article_role_value !== (article_role_value = /*onclick*/ ctx[7] ? 'button' : undefined)) {
				attr(article, "role", article_role_value);
			}

			if (!current || dirty[0] & /*onclick*/ 128 && article_tabindex_value !== (article_tabindex_value = /*onclick*/ ctx[7] ? 0 : undefined)) {
				attr(article, "tabindex", article_tabindex_value);
			}
		},
		i(local) {
			if (current) return;
			if (switch_instance0) transition_in(switch_instance0.$$.fragment, local);
			if (switch_instance1) transition_in(switch_instance1.$$.fragment, local);
			current = true;
		},
		o(local) {
			if (switch_instance0) transition_out(switch_instance0.$$.fragment, local);
			if (switch_instance1) transition_out(switch_instance1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(article);
			}

			if (switch_instance0) destroy_component(switch_instance0);
			if (switch_instance1) destroy_component(switch_instance1);
			/*article_binding*/ ctx[45](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance$3($$self, $$props, $$invalidate) {
	let $eventClick;
	let $_view;
	let $eventAllUpdated;
	let $eventDidMount;
	let $_intlEventTime;
	let $theme;
	let $eventContent;
	let $displayEventEnd;
	let $eventClassNames;
	let $_iClasses;
	let $eventTextColor;
	let $resources;
	let $eventColor;
	let $eventBackgroundColor;
	let $eventMouseEnter;
	let $eventMouseLeave;
	let $_interaction;
	let { chunk } = $$props;
	let { longChunks = {} } = $$props;
	let { displayEventEnd, eventAllUpdated, eventBackgroundColor, eventTextColor, eventClick, eventColor, eventContent, eventClassNames, eventDidMount, eventMouseEnter, eventMouseLeave, resources, theme, _view, _intlEventTime, _interaction, _iClasses, _tasks } = getContext('state');
	component_subscribe($$self, displayEventEnd, value => $$invalidate(38, $displayEventEnd = value));
	component_subscribe($$self, eventAllUpdated, value => $$invalidate(47, $eventAllUpdated = value));
	component_subscribe($$self, eventBackgroundColor, value => $$invalidate(44, $eventBackgroundColor = value));
	component_subscribe($$self, eventTextColor, value => $$invalidate(41, $eventTextColor = value));
	component_subscribe($$self, eventClick, value => $$invalidate(34, $eventClick = value));
	component_subscribe($$self, eventColor, value => $$invalidate(43, $eventColor = value));
	component_subscribe($$self, eventContent, value => $$invalidate(37, $eventContent = value));
	component_subscribe($$self, eventClassNames, value => $$invalidate(39, $eventClassNames = value));
	component_subscribe($$self, eventDidMount, value => $$invalidate(48, $eventDidMount = value));
	component_subscribe($$self, eventMouseEnter, value => $$invalidate(8, $eventMouseEnter = value));
	component_subscribe($$self, eventMouseLeave, value => $$invalidate(9, $eventMouseLeave = value));
	component_subscribe($$self, resources, value => $$invalidate(42, $resources = value));
	component_subscribe($$self, theme, value => $$invalidate(2, $theme = value));
	component_subscribe($$self, _view, value => $$invalidate(35, $_view = value));
	component_subscribe($$self, _intlEventTime, value => $$invalidate(36, $_intlEventTime = value));
	component_subscribe($$self, _interaction, value => $$invalidate(10, $_interaction = value));
	component_subscribe($$self, _iClasses, value => $$invalidate(40, $_iClasses = value));
	let el;
	let event;
	let classes;
	let style;
	let content;
	let timeText;
	let margin = 1;
	let display;
	let onclick;

	onMount(() => {
		if (isFunction($eventDidMount)) {
			$eventDidMount({
				event: toEventWithLocalDates(event),
				timeText,
				el,
				view: toViewWithLocalDates($_view)
			});
		}
	});

	afterUpdate(() => {
		if (isFunction($eventAllUpdated) && !helperEvent(display)) {
			task(() => $eventAllUpdated({ view: toViewWithLocalDates($_view) }), 'eau', _tasks);
		}
	});

	function createHandler(fn, display) {
		return !helperEvent(display) && isFunction(fn)
		? jsEvent => fn({
				event: toEventWithLocalDates(event),
				el,
				jsEvent,
				view: toViewWithLocalDates($_view)
			})
		: undefined;
	}

	function createDragHandler(interaction, resize) {
		return interaction.action
		? jsEvent => interaction.action.drag(event, jsEvent, resize, null, rect(el).top - rect(ancestor(el, 1)).top, chunk.zeroDuration)
		: undefined;
	}

	function reposition() {
		if (!el) {
			return;
		}

		$$invalidate(33, margin = repositionEvent(chunk, longChunks, height(el)));
	}

	function article_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(3, el);
		});
	}

	$$self.$$set = $$props => {
		if ('chunk' in $$props) $$invalidate(30, chunk = $$props.chunk);
		if ('longChunks' in $$props) $$invalidate(31, longChunks = $$props.longChunks);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*chunk*/ 1073741824) {
			$$invalidate(0, event = chunk.event);
		}

		if ($$self.$$.dirty[0] & /*event, display, chunk, style, $theme*/ 1073741863 | $$self.$$.dirty[1] & /*$resources, $eventBackgroundColor, $eventColor, $eventTextColor, margin, $_iClasses, $eventClassNames, $_view*/ 16148) {
			{
				$$invalidate(1, display = event.display);

				// Class & Style
				let bgColor = event.backgroundColor || resourceBackgroundColor(event, $resources) || $eventBackgroundColor || $eventColor;

				let txtColor = event.textColor || resourceTextColor(event, $resources) || $eventTextColor;

				if (bgEvent(display)) {
					$$invalidate(5, style = `width:calc(${chunk.days * 100}% + ${chunk.days - 1}px);`);
				} else {
					$$invalidate(5, style = `width:calc(${chunk.days * 100}% + ${(chunk.days - 1) * 7}px);` + `margin-top:${event._margin ?? margin}px;`);
				}

				if (bgColor) {
					$$invalidate(5, style += `background-color:${bgColor};`);
				}

				if (txtColor) {
					$$invalidate(5, style += `color:${txtColor};`);
				}

				$$invalidate(5, style += event.styles.join(';'));

				$$invalidate(4, classes = [
					bgEvent(display) ? $theme.bgEvent : $theme.event,
					...$_iClasses([], event),
					...createEventClasses($eventClassNames, event, $_view)
				].join(' '));
			}
		}

		if ($$self.$$.dirty[0] & /*chunk, $theme*/ 1073741828 | $$self.$$.dirty[1] & /*$displayEventEnd, $eventContent, $_intlEventTime, $_view*/ 240) {
			// Content
			$$invalidate(6, [timeText, content] = createEventContent(chunk, $displayEventEnd, $eventContent, $theme, $_intlEventTime, $_view), content);
		}

		if ($$self.$$.dirty[0] & /*display*/ 2 | $$self.$$.dirty[1] & /*$eventClick*/ 8) {
			// Onclick handler
			$$invalidate(7, onclick = createHandler($eventClick, display));
		}
	};

	return [
		event,
		display,
		$theme,
		el,
		classes,
		style,
		content,
		onclick,
		$eventMouseEnter,
		$eventMouseLeave,
		$_interaction,
		displayEventEnd,
		eventAllUpdated,
		eventBackgroundColor,
		eventTextColor,
		eventClick,
		eventColor,
		eventContent,
		eventClassNames,
		eventDidMount,
		eventMouseEnter,
		eventMouseLeave,
		resources,
		theme,
		_view,
		_intlEventTime,
		_interaction,
		_iClasses,
		createHandler,
		createDragHandler,
		chunk,
		longChunks,
		reposition,
		margin,
		$eventClick,
		$_view,
		$_intlEventTime,
		$eventContent,
		$displayEventEnd,
		$eventClassNames,
		$_iClasses,
		$eventTextColor,
		$resources,
		$eventColor,
		$eventBackgroundColor,
		article_binding
	];
}

class Event extends SvelteComponent {
	constructor(options) {
		super();

		init(
			this,
			options,
			instance$3,
			create_fragment$3,
			safe_not_equal,
			{
				chunk: 30,
				longChunks: 31,
				reposition: 32
			},
			null,
			[-1, -1]
		);
	}

	get reposition() {
		return this.$$.ctx[32];
	}
}

/* packages/time-grid/src/all-day/Day.svelte generated by Svelte v4.2.19 */

function get_each_context$2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[26] = list[i];
	child_ctx[27] = list;
	child_ctx[28] = i;
	return child_ctx;
}

function get_each_context_1$1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[26] = list[i];
	return child_ctx;
}

// (52:8) {#if !disabled}
function create_if_block_2(ctx) {
	let each_blocks = [];
	let each_1_lookup = new Map();
	let each_1_anchor;
	let current;
	let each_value_1 = ensure_array_like(/*dayBgChunks*/ ctx[6]);
	const get_key = ctx => /*chunk*/ ctx[26].event;

	for (let i = 0; i < each_value_1.length; i += 1) {
		let child_ctx = get_each_context_1$1(ctx, each_value_1, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block_1$1(key, child_ctx));
	}

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert(target, each_1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*dayBgChunks*/ 64) {
				each_value_1 = ensure_array_like(/*dayBgChunks*/ ctx[6]);
				group_outros();
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value_1, each_1_lookup, each_1_anchor.parentNode, outro_and_destroy_block, create_each_block_1$1, each_1_anchor, get_each_context_1$1);
				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value_1.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(each_1_anchor);
			}

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d(detaching);
			}
		}
	};
}

// (53:12) {#each dayBgChunks as chunk (chunk.event)}
function create_each_block_1$1(key_1, ctx) {
	let first;
	let event;
	let current;
	event = new Event({ props: { chunk: /*chunk*/ ctx[26] } });

	return {
		key: key_1,
		first: null,
		c() {
			first = empty();
			create_component(event.$$.fragment);
			this.first = first;
		},
		m(target, anchor) {
			insert(target, first, anchor);
			mount_component(event, target, anchor);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const event_changes = {};
			if (dirty & /*dayBgChunks*/ 64) event_changes.chunk = /*chunk*/ ctx[26];
			event.$set(event_changes);
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(first);
			}

			destroy_component(event, detaching);
		}
	};
}

// (59:4) {#if iChunks[0] && datesEqual(iChunks[0].date, date) && !disabled}
function create_if_block_1(ctx) {
	let div;
	let event;
	let div_class_value;
	let current;
	event = new Event({ props: { chunk: /*iChunks*/ ctx[2][0] } });

	return {
		c() {
			div = element("div");
			create_component(event.$$.fragment);
			attr(div, "class", div_class_value = "" + (/*$theme*/ ctx[10].events + " " + /*$theme*/ ctx[10].preview));
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(event, div, null);
			current = true;
		},
		p(ctx, dirty) {
			const event_changes = {};
			if (dirty & /*iChunks*/ 4) event_changes.chunk = /*iChunks*/ ctx[2][0];
			event.$set(event_changes);

			if (!current || dirty & /*$theme*/ 1024 && div_class_value !== (div_class_value = "" + (/*$theme*/ ctx[10].events + " " + /*$theme*/ ctx[10].preview))) {
				attr(div, "class", div_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			destroy_component(event);
		}
	};
}

// (65:8) {#if !disabled}
function create_if_block$1(ctx) {
	let each_blocks = [];
	let each_1_lookup = new Map();
	let each_1_anchor;
	let current;
	let each_value = ensure_array_like(/*dayChunks*/ ctx[5]);
	const get_key = ctx => /*chunk*/ ctx[26].event;

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context$2(ctx, each_value, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block$2(key, child_ctx));
	}

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert(target, each_1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*dayChunks, longChunks, refs*/ 546) {
				each_value = ensure_array_like(/*dayChunks*/ ctx[5]);
				group_outros();
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value, each_1_lookup, each_1_anchor.parentNode, outro_and_destroy_block, create_each_block$2, each_1_anchor, get_each_context$2);
				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(each_1_anchor);
			}

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d(detaching);
			}
		}
	};
}

// (66:12) {#each dayChunks as chunk, i (chunk.event)}
function create_each_block$2(key_1, ctx) {
	let first;
	let event;
	let i = /*i*/ ctx[28];
	let current;
	const assign_event = () => /*event_binding*/ ctx[24](event, i);
	const unassign_event = () => /*event_binding*/ ctx[24](null, i);

	let event_props = {
		chunk: /*chunk*/ ctx[26],
		longChunks: /*longChunks*/ ctx[1]
	};

	event = new Event({ props: event_props });
	assign_event();

	return {
		key: key_1,
		first: null,
		c() {
			first = empty();
			create_component(event.$$.fragment);
			this.first = first;
		},
		m(target, anchor) {
			insert(target, first, anchor);
			mount_component(event, target, anchor);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (i !== /*i*/ ctx[28]) {
				unassign_event();
				i = /*i*/ ctx[28];
				assign_event();
			}

			const event_changes = {};
			if (dirty & /*dayChunks*/ 32) event_changes.chunk = /*chunk*/ ctx[26];
			if (dirty & /*longChunks*/ 2) event_changes.longChunks = /*longChunks*/ ctx[1];
			event.$set(event_changes);
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(first);
			}

			unassign_event();
			destroy_component(event, detaching);
		}
	};
}

function create_fragment$2(ctx) {
	let div2;
	let div0;
	let div0_class_value;
	let t0;
	let show_if = /*iChunks*/ ctx[2][0] && datesEqual(/*iChunks*/ ctx[2][0].date, /*date*/ ctx[0]) && !/*disabled*/ ctx[4];
	let t1;
	let div1;
	let div1_class_value;
	let div2_class_value;
	let current;
	let mounted;
	let dispose;
	let if_block0 = !/*disabled*/ ctx[4] && create_if_block_2(ctx);
	let if_block1 = show_if && create_if_block_1(ctx);
	let if_block2 = !/*disabled*/ ctx[4] && create_if_block$1(ctx);

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			if (if_block0) if_block0.c();
			t0 = space();
			if (if_block1) if_block1.c();
			t1 = space();
			div1 = element("div");
			if (if_block2) if_block2.c();
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[10].bgEvents);
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[10].events);

			attr(div2, "class", div2_class_value = "" + (/*$theme*/ ctx[10].day + " " + /*$theme*/ ctx[10].weekdays?.[/*date*/ ctx[0].getUTCDay()] + (/*isToday*/ ctx[7] ? ' ' + /*$theme*/ ctx[10].today : '') + (/*highlight*/ ctx[8]
			? ' ' + /*$theme*/ ctx[10].highlight
			: '') + (/*disabled*/ ctx[4]
			? ' ' + /*$theme*/ ctx[10].disabled
			: '')));

			attr(div2, "role", "cell");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			if (if_block0) if_block0.m(div0, null);
			append(div2, t0);
			if (if_block1) if_block1.m(div2, null);
			append(div2, t1);
			append(div2, div1);
			if (if_block2) if_block2.m(div1, null);
			/*div2_binding*/ ctx[25](div2);
			current = true;

			if (!mounted) {
				dispose = listen(div2, "pointerdown", function () {
					if (is_function(!/*disabled*/ ctx[4]
					? /*$_interaction*/ ctx[11].action?.select
					: undefined)) (!/*disabled*/ ctx[4]
					? /*$_interaction*/ ctx[11].action?.select
					: undefined).apply(this, arguments);
				});

				mounted = true;
			}
		},
		p(new_ctx, [dirty]) {
			ctx = new_ctx;

			if (!/*disabled*/ ctx[4]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);

					if (dirty & /*disabled*/ 16) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_2(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(div0, null);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (!current || dirty & /*$theme*/ 1024 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[10].bgEvents)) {
				attr(div0, "class", div0_class_value);
			}

			if (dirty & /*iChunks, date, disabled*/ 21) show_if = /*iChunks*/ ctx[2][0] && datesEqual(/*iChunks*/ ctx[2][0].date, /*date*/ ctx[0]) && !/*disabled*/ ctx[4];

			if (show_if) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty & /*iChunks, date, disabled*/ 21) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_1(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div2, t1);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (!/*disabled*/ ctx[4]) {
				if (if_block2) {
					if_block2.p(ctx, dirty);

					if (dirty & /*disabled*/ 16) {
						transition_in(if_block2, 1);
					}
				} else {
					if_block2 = create_if_block$1(ctx);
					if_block2.c();
					transition_in(if_block2, 1);
					if_block2.m(div1, null);
				}
			} else if (if_block2) {
				group_outros();

				transition_out(if_block2, 1, 1, () => {
					if_block2 = null;
				});

				check_outros();
			}

			if (!current || dirty & /*$theme*/ 1024 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[10].events)) {
				attr(div1, "class", div1_class_value);
			}

			if (!current || dirty & /*$theme, date, isToday, highlight, disabled*/ 1425 && div2_class_value !== (div2_class_value = "" + (/*$theme*/ ctx[10].day + " " + /*$theme*/ ctx[10].weekdays?.[/*date*/ ctx[0].getUTCDay()] + (/*isToday*/ ctx[7] ? ' ' + /*$theme*/ ctx[10].today : '') + (/*highlight*/ ctx[8]
			? ' ' + /*$theme*/ ctx[10].highlight
			: '') + (/*disabled*/ ctx[4]
			? ' ' + /*$theme*/ ctx[10].disabled
			: '')))) {
				attr(div2, "class", div2_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			transition_in(if_block1);
			transition_in(if_block2);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			transition_out(if_block1);
			transition_out(if_block2);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div2);
			}

			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
			/*div2_binding*/ ctx[25](null);
			mounted = false;
			dispose();
		}
	};
}

function instance$2($$self, $$props, $$invalidate) {
	let $validRange;
	let $highlightedDates;
	let $_today;
	let $theme;
	let $_interaction;
	let { date } = $$props;
	let { chunks } = $$props;
	let { bgChunks } = $$props;
	let { longChunks } = $$props;
	let { iChunks = [] } = $$props;
	let { resource = undefined } = $$props;
	let { highlightedDates, theme, validRange, _interaction, _today } = getContext('state');
	component_subscribe($$self, highlightedDates, value => $$invalidate(22, $highlightedDates = value));
	component_subscribe($$self, theme, value => $$invalidate(10, $theme = value));
	component_subscribe($$self, validRange, value => $$invalidate(21, $validRange = value));
	component_subscribe($$self, _interaction, value => $$invalidate(11, $_interaction = value));
	component_subscribe($$self, _today, value => $$invalidate(23, $_today = value));
	let el;
	let dayChunks, dayBgChunks;
	let isToday, highlight, disabled;
	let refs = [];

	function reposition() {
		if (!disabled) {
			runReposition(refs, dayChunks);
		}
	}

	function event_binding($$value, i) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			refs[i] = $$value;
			$$invalidate(9, refs);
		});
	}

	function div2_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(3, el);
		});
	}

	$$self.$$set = $$props => {
		if ('date' in $$props) $$invalidate(0, date = $$props.date);
		if ('chunks' in $$props) $$invalidate(17, chunks = $$props.chunks);
		if ('bgChunks' in $$props) $$invalidate(18, bgChunks = $$props.bgChunks);
		if ('longChunks' in $$props) $$invalidate(1, longChunks = $$props.longChunks);
		if ('iChunks' in $$props) $$invalidate(2, iChunks = $$props.iChunks);
		if ('resource' in $$props) $$invalidate(19, resource = $$props.resource);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*chunks, date*/ 131073) {
			$$invalidate(5, dayChunks = chunks.filter(chunk => datesEqual(chunk.date, date)));
		}

		if ($$self.$$.dirty & /*bgChunks, date*/ 262145) {
			$$invalidate(6, dayBgChunks = bgChunks.filter(bgChunk => datesEqual(bgChunk.date, date)));
		}

		if ($$self.$$.dirty & /*date, $_today*/ 8388609) {
			$$invalidate(7, isToday = datesEqual(date, $_today));
		}

		if ($$self.$$.dirty & /*$highlightedDates, date*/ 4194305) {
			$$invalidate(8, highlight = $highlightedDates.some(d => datesEqual(d, date)));
		}

		if ($$self.$$.dirty & /*date, $validRange*/ 2097153) {
			$$invalidate(4, disabled = outsideRange(date, $validRange));
		}

		if ($$self.$$.dirty & /*el, date, resource, disabled*/ 524313) {
			// dateFromPoint
			if (el) {
				setPayload(el, () => ({
					allDay: true,
					date,
					resource,
					dayEl: el,
					disabled
				}));
			}
		}
	};

	return [
		date,
		longChunks,
		iChunks,
		el,
		disabled,
		dayChunks,
		dayBgChunks,
		isToday,
		highlight,
		refs,
		$theme,
		$_interaction,
		highlightedDates,
		theme,
		validRange,
		_interaction,
		_today,
		chunks,
		bgChunks,
		resource,
		reposition,
		$validRange,
		$highlightedDates,
		$_today,
		event_binding,
		div2_binding
	];
}

class Day extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance$2, create_fragment$2, safe_not_equal, {
			date: 0,
			chunks: 17,
			bgChunks: 18,
			longChunks: 1,
			iChunks: 2,
			resource: 19,
			reposition: 20
		});
	}

	get reposition() {
		return this.$$.ctx[20];
	}
}

/* packages/time-grid/src/all-day/Week.svelte generated by Svelte v4.2.19 */

function get_each_context$1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[26] = list[i];
	child_ctx[27] = list;
	child_ctx[28] = i;
	return child_ctx;
}

// (66:0) {#each dates as date, i}
function create_each_block$1(ctx) {
	let day;
	let i = /*i*/ ctx[28];
	let current;
	const assign_day = () => /*day_binding*/ ctx[23](day, i);
	const unassign_day = () => /*day_binding*/ ctx[23](null, i);

	let day_props = {
		date: /*date*/ ctx[26],
		chunks: /*chunks*/ ctx[2],
		bgChunks: /*bgChunks*/ ctx[3],
		longChunks: /*longChunks*/ ctx[4],
		iChunks: /*iChunks*/ ctx[5],
		resource: /*resource*/ ctx[1]
	};

	day = new Day({ props: day_props });
	assign_day();

	return {
		c() {
			create_component(day.$$.fragment);
		},
		m(target, anchor) {
			mount_component(day, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (i !== /*i*/ ctx[28]) {
				unassign_day();
				i = /*i*/ ctx[28];
				assign_day();
			}

			const day_changes = {};
			if (dirty & /*dates*/ 1) day_changes.date = /*date*/ ctx[26];
			if (dirty & /*chunks*/ 4) day_changes.chunks = /*chunks*/ ctx[2];
			if (dirty & /*bgChunks*/ 8) day_changes.bgChunks = /*bgChunks*/ ctx[3];
			if (dirty & /*longChunks*/ 16) day_changes.longChunks = /*longChunks*/ ctx[4];
			if (dirty & /*iChunks*/ 32) day_changes.iChunks = /*iChunks*/ ctx[5];
			if (dirty & /*resource*/ 2) day_changes.resource = /*resource*/ ctx[1];
			day.$set(day_changes);
		},
		i(local) {
			if (current) return;
			transition_in(day.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(day.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			unassign_day();
			destroy_component(day, detaching);
		}
	};
}

function create_fragment$1(ctx) {
	let each_1_anchor;
	let current;
	let mounted;
	let dispose;
	let each_value = ensure_array_like(/*dates*/ ctx[0]);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block$1(get_each_context$1(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert(target, each_1_anchor, anchor);
			current = true;

			if (!mounted) {
				dispose = listen(window, "resize", /*reposition*/ ctx[13]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*dates, chunks, bgChunks, longChunks, iChunks, resource, refs*/ 127) {
				each_value = ensure_array_like(/*dates*/ ctx[0]);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context$1(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block$1(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(each_1_anchor);
			}

			destroy_each(each_blocks, detaching);
			mounted = false;
			dispose();
		}
	};
}

function instance$1($$self, $$props, $$invalidate) {
	let $hiddenDays;
	let $_iEvents;
	let $_events;
	let $resources;
	let $filterEventsWithResources;
	let $validRange;
	let { dates } = $$props;
	let { resource = undefined } = $$props;
	let { _events, _iEvents, _queue2, hiddenDays, resources, filterEventsWithResources, validRange } = getContext('state');
	component_subscribe($$self, _events, value => $$invalidate(19, $_events = value));
	component_subscribe($$self, _iEvents, value => $$invalidate(18, $_iEvents = value));
	component_subscribe($$self, hiddenDays, value => $$invalidate(17, $hiddenDays = value));
	component_subscribe($$self, resources, value => $$invalidate(20, $resources = value));
	component_subscribe($$self, filterEventsWithResources, value => $$invalidate(21, $filterEventsWithResources = value));
	component_subscribe($$self, validRange, value => $$invalidate(22, $validRange = value));
	let chunks, bgChunks, longChunks, iChunks = [];
	let start;
	let end;
	let refs = [];
	let resourceFilter;
	let debounceHandle = {};

	function reposition() {
		debounce(() => runReposition(refs, dates), debounceHandle, _queue2);
	}

	function day_binding($$value, i) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			refs[i] = $$value;
			$$invalidate(6, refs);
		});
	}

	$$self.$$set = $$props => {
		if ('dates' in $$props) $$invalidate(0, dates = $$props.dates);
		if ('resource' in $$props) $$invalidate(1, resource = $$props.resource);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*dates, $validRange*/ 4194305) {
			{
				$$invalidate(14, start = limitToRange(dates[0], $validRange));
				$$invalidate(15, end = addDay(cloneDate(limitToRange(dates.at(-1), $validRange))));
			}
		}

		if ($$self.$$.dirty & /*resource, $filterEventsWithResources, $resources*/ 3145730) {
			$$invalidate(16, resourceFilter = resource ?? ($filterEventsWithResources ? $resources : undefined));
		}

		if ($$self.$$.dirty & /*$_events, start, end, resourceFilter, bgChunks, chunks, $hiddenDays*/ 770060) {
			{
				$$invalidate(2, chunks = []);
				$$invalidate(3, bgChunks = []);

				for (let event of $_events) {
					if (event.allDay && eventIntersects(event, start, end, resourceFilter)) {
						let chunk = createEventChunk(event, start, end);

						if (bgEvent(event.display)) {
							bgChunks.push(chunk);
						} else {
							chunks.push(chunk);
						}
					}
				}

				prepareEventChunks(bgChunks, $hiddenDays);
				$$invalidate(4, longChunks = prepareEventChunks(chunks, $hiddenDays));

				// Run reposition only when events get changed
				reposition();
			}
		}

		if ($$self.$$.dirty & /*$_iEvents, start, end, resource, $hiddenDays*/ 442370) {
			$$invalidate(5, iChunks = $_iEvents.map(event => {
				let chunk;

				if (event && event.allDay && eventIntersects(event, start, end, resource)) {
					chunk = createEventChunk(event, start, end);
					prepareEventChunks([chunk], $hiddenDays);
				} else {
					chunk = null;
				}

				return chunk;
			}));
		}
	};

	return [
		dates,
		resource,
		chunks,
		bgChunks,
		longChunks,
		iChunks,
		refs,
		_events,
		_iEvents,
		hiddenDays,
		resources,
		filterEventsWithResources,
		validRange,
		reposition,
		start,
		end,
		resourceFilter,
		$hiddenDays,
		$_iEvents,
		$_events,
		$resources,
		$filterEventsWithResources,
		$validRange,
		day_binding
	];
}

class Week extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$1, create_fragment$1, safe_not_equal, { dates: 0, resource: 1 });
	}
}

/* packages/time-grid/src/View.svelte generated by Svelte v4.2.19 */

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[10] = list[i];
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[10] = list[i];
	return child_ctx;
}

// (14:8) {#each $_viewDates as date}
function create_each_block_1(ctx) {
	let div;
	let time;
	let time_datetime_value;
	let time_aria_label_value;
	let setContent_action;
	let t;
	let div_class_value;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			time = element("time");
			t = space();
			attr(time, "datetime", time_datetime_value = toISOString(/*date*/ ctx[10], 10));
			attr(time, "aria-label", time_aria_label_value = /*$_intlDayHeaderAL*/ ctx[2].format(/*date*/ ctx[10]));
			attr(div, "class", div_class_value = "" + (/*$theme*/ ctx[0].day + " " + /*$theme*/ ctx[0].weekdays?.[/*date*/ ctx[10].getUTCDay()]));
			attr(div, "role", "columnheader");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, time);
			append(div, t);

			if (!mounted) {
				dispose = action_destroyer(setContent_action = setContent.call(null, time, /*$_intlDayHeader*/ ctx[3].format(/*date*/ ctx[10])));
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*$_viewDates*/ 2 && time_datetime_value !== (time_datetime_value = toISOString(/*date*/ ctx[10], 10))) {
				attr(time, "datetime", time_datetime_value);
			}

			if (dirty & /*$_intlDayHeaderAL, $_viewDates*/ 6 && time_aria_label_value !== (time_aria_label_value = /*$_intlDayHeaderAL*/ ctx[2].format(/*date*/ ctx[10]))) {
				attr(time, "aria-label", time_aria_label_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty & /*$_intlDayHeader, $_viewDates*/ 10) setContent_action.update.call(null, /*$_intlDayHeader*/ ctx[3].format(/*date*/ ctx[10]));

			if (dirty & /*$theme, $_viewDates*/ 3 && div_class_value !== (div_class_value = "" + (/*$theme*/ ctx[0].day + " " + /*$theme*/ ctx[0].weekdays?.[/*date*/ ctx[10].getUTCDay()]))) {
				attr(div, "class", div_class_value);
			}
		},
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			mounted = false;
			dispose();
		}
	};
}

// (13:4) <Section>
function create_default_slot_2(ctx) {
	let each_1_anchor;
	let each_value_1 = ensure_array_like(/*$_viewDates*/ ctx[1]);
	let each_blocks = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert(target, each_1_anchor, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*$theme, $_viewDates, $_intlDayHeaderAL, $_intlDayHeader*/ 15) {
				each_value_1 = ensure_array_like(/*$_viewDates*/ ctx[1]);
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_1(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_1.length;
			}
		},
		d(detaching) {
			if (detaching) {
				detach(each_1_anchor);
			}

			destroy_each(each_blocks, detaching);
		}
	};
}

// (26:0) {#if $allDaySlot}
function create_if_block(ctx) {
	let div2;
	let div1;
	let section;
	let t;
	let div0;
	let div0_class_value;
	let div1_class_value;
	let div2_class_value;
	let current;

	section = new Section({
			props: {
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			div2 = element("div");
			div1 = element("div");
			create_component(section.$$.fragment);
			t = space();
			div0 = element("div");
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[0].hiddenScroll);
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[0].content);
			attr(div2, "class", div2_class_value = /*$theme*/ ctx[0].allDay);
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div1);
			mount_component(section, div1, null);
			append(div1, t);
			append(div1, div0);
			current = true;
		},
		p(ctx, dirty) {
			const section_changes = {};

			if (dirty & /*$$scope, $_viewDates*/ 32770) {
				section_changes.$$scope = { dirty, ctx };
			}

			section.$set(section_changes);

			if (!current || dirty & /*$theme*/ 1 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[0].hiddenScroll)) {
				attr(div0, "class", div0_class_value);
			}

			if (!current || dirty & /*$theme*/ 1 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[0].content)) {
				attr(div1, "class", div1_class_value);
			}

			if (!current || dirty & /*$theme*/ 1 && div2_class_value !== (div2_class_value = /*$theme*/ ctx[0].allDay)) {
				attr(div2, "class", div2_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(section.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(section.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div2);
			}

			destroy_component(section);
		}
	};
}

// (29:12) <Section>
function create_default_slot_1(ctx) {
	let week;
	let current;
	week = new Week({ props: { dates: /*$_viewDates*/ ctx[1] } });

	return {
		c() {
			create_component(week.$$.fragment);
		},
		m(target, anchor) {
			mount_component(week, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const week_changes = {};
			if (dirty & /*$_viewDates*/ 2) week_changes.dates = /*$_viewDates*/ ctx[1];
			week.$set(week_changes);
		},
		i(local) {
			if (current) return;
			transition_in(week.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(week.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(week, detaching);
		}
	};
}

// (37:0) {#each $_viewDates as date}
function create_each_block(ctx) {
	let day;
	let current;
	day = new Day$1({ props: { date: /*date*/ ctx[10] } });

	return {
		c() {
			create_component(day.$$.fragment);
		},
		m(target, anchor) {
			mount_component(day, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const day_changes = {};
			if (dirty & /*$_viewDates*/ 2) day_changes.date = /*date*/ ctx[10];
			day.$set(day_changes);
		},
		i(local) {
			if (current) return;
			transition_in(day.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(day.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(day, detaching);
		}
	};
}

// (36:0) <Body>
function create_default_slot(ctx) {
	let each_1_anchor;
	let current;
	let each_value = ensure_array_like(/*$_viewDates*/ ctx[1]);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert(target, each_1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*$_viewDates*/ 2) {
				each_value = ensure_array_like(/*$_viewDates*/ ctx[1]);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(each_1_anchor);
			}

			destroy_each(each_blocks, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let section;
	let t0;
	let div0;
	let div0_class_value;
	let div1_class_value;
	let t1;
	let t2;
	let body;
	let current;

	section = new Section({
			props: {
				$$slots: { default: [create_default_slot_2] },
				$$scope: { ctx }
			}
		});

	let if_block = /*$allDaySlot*/ ctx[4] && create_if_block(ctx);

	body = new Body({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			div1 = element("div");
			create_component(section.$$.fragment);
			t0 = space();
			div0 = element("div");
			t1 = space();
			if (if_block) if_block.c();
			t2 = space();
			create_component(body.$$.fragment);
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[0].hiddenScroll);
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[0].header);
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			mount_component(section, div1, null);
			append(div1, t0);
			append(div1, div0);
			insert(target, t1, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, t2, anchor);
			mount_component(body, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const section_changes = {};

			if (dirty & /*$$scope, $_viewDates, $theme, $_intlDayHeaderAL, $_intlDayHeader*/ 32783) {
				section_changes.$$scope = { dirty, ctx };
			}

			section.$set(section_changes);

			if (!current || dirty & /*$theme*/ 1 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[0].hiddenScroll)) {
				attr(div0, "class", div0_class_value);
			}

			if (!current || dirty & /*$theme*/ 1 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[0].header)) {
				attr(div1, "class", div1_class_value);
			}

			if (/*$allDaySlot*/ ctx[4]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*$allDaySlot*/ 16) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(t2.parentNode, t2);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}

			const body_changes = {};

			if (dirty & /*$$scope, $_viewDates*/ 32770) {
				body_changes.$$scope = { dirty, ctx };
			}

			body.$set(body_changes);
		},
		i(local) {
			if (current) return;
			transition_in(section.$$.fragment, local);
			transition_in(if_block);
			transition_in(body.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(section.$$.fragment, local);
			transition_out(if_block);
			transition_out(body.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div1);
				detach(t1);
				detach(t2);
			}

			destroy_component(section);
			if (if_block) if_block.d(detaching);
			destroy_component(body, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $theme;
	let $_viewDates;
	let $_intlDayHeaderAL;
	let $_intlDayHeader;
	let $allDaySlot;
	let { _viewDates, _intlDayHeader, _intlDayHeaderAL, allDaySlot, theme } = getContext('state');
	component_subscribe($$self, _viewDates, value => $$invalidate(1, $_viewDates = value));
	component_subscribe($$self, _intlDayHeader, value => $$invalidate(3, $_intlDayHeader = value));
	component_subscribe($$self, _intlDayHeaderAL, value => $$invalidate(2, $_intlDayHeaderAL = value));
	component_subscribe($$self, allDaySlot, value => $$invalidate(4, $allDaySlot = value));
	component_subscribe($$self, theme, value => $$invalidate(0, $theme = value));

	return [
		$theme,
		$_viewDates,
		$_intlDayHeaderAL,
		$_intlDayHeader,
		$allDaySlot,
		_viewDates,
		_intlDayHeader,
		_intlDayHeaderAL,
		allDaySlot,
		theme
	];
}

class View extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

var index = {
	createOptions(options) {
		// Common options
		options.buttonText.timeGridDay = 'day';
		options.buttonText.timeGridWeek = 'week';
		options.view = 'timeGridWeek';
		options.views.timeGridDay = {
			buttonText: btnTextDay,
			component: View,
			dayHeaderFormat: {weekday: 'long'},
			duration: {days: 1},
			theme: themeView('ec-time-grid ec-day-view'),
			titleFormat: {year: 'numeric', month: 'long', day: 'numeric'}
		};
		options.views.timeGridWeek = {
			buttonText: btnTextWeek,
			component: View,
			duration: {weeks: 1},
			theme: themeView('ec-time-grid ec-week-view')
		};
	},

	createStores(state) {
		state._slotTimeLimits = slotTimeLimits(state);  // flexible limits
		state._times = times(state);
	}
};

export { Body, Day$1 as Day, Section, Week, index as default };
